import React from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'
import './MapPanel.scss'
import 'leaflet'
import 'leaflet-draw';
import { Collapse } from 'antd';
import { updateMainStateAttr  } from '../../actions';
import { connect } from "react-redux";

const { Panel } = Collapse;

class MapPanel extends React.Component {
  state = {
    mapCenter: [40.4, -85.7],
    mapZoom: 3,
    wktString: null,
    editableLayers: null,
    shapeType: null,
    shapeLatLngs: null,
    geoShapeString: '',
  }

  componentDidMount = () => {
    const self = this;
    const L = window.L;
    
    // Setting the map boundaries. 
    const mapBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

    let map = L.map("map", {
      maxBounds: mapBounds,
      minZoom: 2,
      maxBoundsViscosity: 1.0
    }).setView(this.state.mapCenter, this.state.mapZoom);

    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      noWrap: true,
    }).addTo(map);


    // editableLayers is where our user drawn shapes will be stored
    let editableLayers = new L.FeatureGroup();
    map.addLayer(editableLayers);

    let leafletControlOptions = {
      // What shapes we can draw on map
      // Disable shapes we don't need
      draw: {
        rectangle: true,
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons 
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects 
            message: 'Polygon points cannot intersect.' // Message that will show when intersect 
          }
        },
        polyline: false,
        circle: false,
        marker: false,
        circlemarker: false
      },

      edit: {
        featureGroup: editableLayers, // required
        edit: false,
        remove: true
      }
    };

    // Add the leaflet control panel to the map
    let drawControl = new L.Control.Draw(leafletControlOptions);
    map.addControl(drawControl);

    self.setState({
      editableLayers: editableLayers
    })

    // Event trigger for when a completed shape is drawn on the map.
    map.on(L.Draw.Event.CREATED, function (e) {
      self.initLeafletShape(e)
    });

    // Event trigger for when starting to draw a shape.
    map.on(L.Draw.Event.DRAWSTART, function () {
      self.clearGeoShapeStates();
    });

    // Event trigger for when clearing polygon/bbox.
    map.on(L.Draw.Event.DELETED, function () {
      self.clearGeoShapeStates();
    });
  }

  /**
   * Triggered when a user has completed drawing their leaflet shape.
   * @param {event} e 
   */
  initLeafletShape = (e) => {
    // type can be square, polygon, etc
    let shapeType = e.layerType
    let layer = e.layer;
    let geoShapeString = this.createWKTString(shapeType, layer._latlngs[0])

    this.setState({ 
      shapeType: shapeType,
      shapeLatLngs: layer._latlngs[0],
      editableLayers: this.state.editableLayers.addLayer(layer),
      geoShapeString: geoShapeString
    })      

    this.props.updateMainStateAttr({
      attr: 'geoShapeString',
      value: geoShapeString
    })
  }

  /**
   * Function to reset the geo shape states
   */
  clearGeoShapeStates = () => {
    // Clear the shapes drawn on map
    this.state.editableLayers.clearLayers();
    // Clear the shape WKT string
    this.setState({ 
      geoShapeString: ''
    })

    // Clear the shape WKT string in redux state
    this.props.updateMainStateAttr({
      attr: 'geoShapeString',
      value: ''
    })
  }


  /**
   * Given the geo boundary shape type, return the appropriate string representation
   * @param {string} shapeType 
   * @param {LatLng} shapeLatLngs 
   */
  createWKTString = (shapeType, shapeLatLngs) => {
    if (shapeType === 'rectangle') {
      return this.toBBoxString(shapeLatLngs)
    }
    if (shapeType === 'polygon') {
      return this.toPolygonWKTString(shapeLatLngs)
    }
  }

  /**
   * Given an array of Leaflet LatLngs, 
   * Return a string in the format 'bbox(minLon, minLat, maxLon, maxLat)'
   * @param {LatLng} latlngs 
   */
  toBBoxString = (latlngs) => {
    let minLat = latlngs[0].lat
    let maxLat = latlngs[1].lat
    let minLon = latlngs[0].lng
    let maxLon = latlngs[2].lng

    return `bbox(${minLon},${minLat},${maxLon},${maxLat})`
  }

  /**
   * Given an array of Leaflet LatLngs,
   * Return a string that follows the Polygon WKT string format
   * https://en.wikipedia.org/wiki/Well-known_text_representation_of_geometry#Geometric_objects
   * @param {LatLng} latlngs 
   */
  toPolygonWKTString = (latlngs) => {
    let listOfPolygonPoints = [];

    for (let i in latlngs) {
      listOfPolygonPoints.push(`${latlngs[i].lng} ${latlngs[i].lat}`)
    }

    // closing the polygon shape
    listOfPolygonPoints.push(`${latlngs[0].lng} ${latlngs[0].lat}`)

    return `polygon('POLYGON((${listOfPolygonPoints}))')`
  }

  render() {
    return (
      <Collapse className="map-panel form-collapse-panel"  defaultActiveKey={['1']}>
        <Panel header="Geospatial Bounds" key="1">
          <div className="map-panel-container">
            <div id="map"></div>
          </div>
          <span className="wkt-text-format">{ this.state.geoShapeString }</span>
        </Panel>
      </Collapse>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    }
  }
}

export default connect(null, mapDispatchToProps)(MapPanel);
import React from 'react';
import './ExtraOptionItem.scss'
import { Checkbox, Popover, Collapse, Radio, Input} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { updateMainStateAttr, updateExtraOptionStateAttr  } from '../../../../actions';
import _ from 'lodash'
import { connect } from "react-redux";

const { Panel } = Collapse;
const { TextArea } = Input;

class ExtraOptionItem extends React.Component {
  state = {
    optionEnabled: false,
    activeKey: 0,
    radioOptions: [
      { label: 'Terminal Points', value: 1 },
      { label: 'Non Terminal Points', value: 0 }
    ],
    optionValue: this.props.serverFunction.function_parameters[0].options[0].name === 'terminal_indicator' ? 1 : null
  }

  componentDidMount = () => {
    this.updateReduxState = _.debounce(this.updateReduxState, 250);
  }

  /**
   * event action to open/collapse the additional option parameters
   * @param {*} e 
   */
  checkboxOnClick = (e) => {
    this.setState({
      // 0 and 1 are used as boolean values for the collapse feature
      activeKey: this.state.activeKey === 0 ? 1 : 0,
    }, () => { this.updateReduxState() })
  }

  /**
   * event action when toggling between radio options
   * @param {*} e 
   */
  radioOnChange = (e) => {
    this.setState({
      optionValue: e.target.value,
    }, () => { this.updateReduxState() });
  };

  /**
   * event action when updating the text input field
   * @param {*} e 
   */
  onTextInput = (e) => {
    this.setState({
      optionValue: e.target.value
    }, () => { this.updateReduxState() })
  }

  /**
   * update the redux state for the current extra option
   */
  updateReduxState = () => {
    this.props.updateExtraOptionStateAttr({
      type: this.props.serverFunction.function_name,
      value: this.state.activeKey === 0 ? null : this.state.optionValue
    })
  }

  render() {
    const self = this;
    return (
      <div className="extra-option-item">
        <Checkbox onClick={this.checkboxOnClick}>
          <span><b>{this.props.serverFunction.function_long_name}</b></span>
          <Popover content={this.props.serverFunction.function_description}>
              <QuestionCircleOutlined />
          </Popover>
        </Checkbox>
        <Collapse bordered={false} ghost={true} activeKey={this.state.activeKey}>
            <Panel showArrow={false} key='1'>

              {/* Schema used for these serverside functions are a bit overkill.
              Instead of setting up a bunch components, conditionals, helper components, 
              We're just going to have a check, and simply render what needs to be rendered. */}
              { this.props.serverFunction.function_parameters[0].options[0].name === 'terminal_indicator' &&
                  <Radio.Group
                    options={self.state.radioOptions}
                    onChange={self.radioOnChange}
                    value={self.state.optionValue}
                    optionType="button"
                    buttonStyle="solid"
                  />
              }

              { this.props.serverFunction.function_parameters[0].options[0].name === 'feature_id_list' &&
                  <TextArea autoSize placeholder="Comma separated list of Feature IDs"  onChange={this.onTextInput} value={this.state.optionValue}></TextArea>
              }

            </Panel>
          </Collapse>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  extraOptions: state.form.extra_options
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    },
    updateExtraOptionStateAttr(value){
      dispatch(updateExtraOptionStateAttr(value));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraOptionItem);
import React from 'react';
import axios from 'axios';
import './CatalogView.scss';
import CatalogItem from './components/CatalogItem/';
import ErrorPageView from '../ErrorPageView/';
import { connect } from "react-redux";

import { Card, Divider} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { initCatalogState } from '../../actions';

class CatalogView extends React.Component {
  state = {
    data: null,
    loaded: false,
    failedToLoad: false
  }

  componentDidMount = () => {
    let requestLocation = process.env.REACT_APP_BACKEND_SERVICE_URL
    let requestURL = `${requestLocation}/datasets_catalog/available_datasets`;

    axios.get(`${requestURL}`)
      .then(res => {
        this.props.initCatalogState(res.data)
        this.setState({ 
          data: res.data,
          loaded: true
         });
      })
      .catch(res => {
        this.setState({ 
          failedToLoad: true
         });
      })
  }
  
  render() {
    return (
      <div>

        {!this.state.loaded && !this.state.failedToLoad &&
          <div className="loader-icon">
            <LoadingOutlined style={{ color: '#1890ff' }} />
          </div>  
        }

        {this.state.loaded && !this.state.failedToLoad &&
          <Card className="App catalog" style={{ width: 850 }}>
            <div className="catalog-view">
              <h2>{this.state.data.catalog_title}</h2>
              <Divider/>
              {this.state.data.datasets.map(function(item){
                return <CatalogItem key={item['dataset_id']} item={item}></CatalogItem>
              })}
            </div>
          </Card>
        }

        {this.state.failedToLoad && 
          <ErrorPageView
            title="Oops!"
            subtitle="An error occured while loading the catalog."
            allowRefresh={true}
            ></ErrorPageView>
        }
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    initCatalogState(value){
      dispatch(initCatalogState(value))
    }
  }
}

export default connect(null, mapDispatchToProps)(CatalogView);
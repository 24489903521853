import React from 'react';
import axios from 'axios';
import './FormView.scss';
import { connect } from "react-redux";

import { Card, Divider, Drawer } from 'antd';
import { QuestionCircleTwoTone, LoadingOutlined } from '@ant-design/icons';

import AttributesTable from '../AttributesTable/';
import DimensionsPanel from '../DimensionsPanel/';
import VariablesPanel from '../VariablesPanel/';
import MapPanel from '../MapPanel/';
import ExtraOptionsPanel from '../ExtraOptionsPanel/';
import HelpGuideView from '../HelpGuideView/';
import ExportContainer from '../ExportContainer/';
import ErrorPageView from '../ErrorPageView/';
import DataUrl from '../DataUrl/';

import { initFormState } from '../../actions';


class FormView extends React.Component {
  state = {
    loaded: false,
    data: null,
    showDrawer: false,
    title: null,
    datasetVersion: null,
    failedToLoad: false,
    errorMessage: null,
  }
    
  componentDidMount = () => {
    let requestURL;
    let requestLocation = process.env.REACT_APP_BACKEND_SERVICE_URL

    const pathname = window.location.pathname
    const dataset = pathname.split('/').slice(-2)[0]
    const version = pathname.split('/').slice(-1)[0]

    if (version === 'best_valid_range' && this.getTimeRange()) {
      requestURL = `${requestLocation}/dap/${dataset}/${this.getTimeRange()}/${version}.json`
    } else {
      requestURL = `${requestLocation}/dap/${dataset}/${version}.json`
    }

    if (pathname.split('/').length === 4 && pathname.split('/')[3] !== '') {
      axios.get(requestURL)
        .then(res => {
          const dataResponse = res.data

          // The form endpoint loads pretty slow.
          // To make dev easier, we're going to use the hardcoded json response
          //const dataResponse = test_json
          this.props.initFormState(dataResponse)

          const dataset = dataResponse.dataset
          this.setState({
            data: dataset,
            extraOptions: dataResponse.extra_options,
            title: dataset.db_title ? dataset.db_title : dataset.path,
            datasetVersion: dataset.path ? `(${dataset.path.split('/')[dataset.path.split('/').length - 1]})` : '',
            loaded: true
          });
        })
        .catch(error => {
          this.setState({
            failedToLoad: true,
            errorMessage: error.response.data ? error.response.data : null
          });
        })
    }
  }

  getTimeRange = () => {
    if (this.props.form.start_time && this.props.form.end_time) {
      return `${this.props.form.start_time}/${this.props.form.end_time}`
    }
    return false;
  }

  /**
   * Toggles the help drawer display state.
   */
  toggleDrawer = () => {
    this.setState({
      showDrawer: !this.state.showDrawer
    })
  }

  /**
   * Check to see if our server side functions contains geoboundary options
   * Returns boolean
   */
  containsGeoBoundaryOptions = () => {
    const serverSideFunctions = this.state.extraOptions.server_side_functions
    return serverSideFunctions ? serverSideFunctions.some(func => func['function_type'] === 'geospatial_selection') : false;
  }

  /**
   * Check to see if the dataset contains extra options (not include geo boundary)
   * Returns boolean
   */
  containsServerSideFunctions = () => {
    const serverSideFunctions = this.state.extraOptions.server_side_functions.filter(function (el) {
      return el.function_type !== 'geospatial_selection'
    });
    return serverSideFunctions.length ? true : false;
  }
  render() {
    return (
      <div>
        {!this.state.loaded && !this.state.failedToLoad &&
          <div className="loader-icon">
            <LoadingOutlined style={{ color: '#1890ff' }} />
          </div>  
        }

        {this.state.loaded &&
          <Card className="App" style={{ width: 850 }}>
            <div className="form-container">
              <div className="card-header-container">
                <h4 className="dataform-title">Dataset Access Form</h4>
                <h5 className="dataset-title">{this.state.title} <span className="dataset-version">{this.state.datasetVersion}</span></h5>
                <QuestionCircleTwoTone className="main-help" onClick={this.toggleDrawer} />
              </div>
            
              <AttributesTable attributes={this.state.data.attrs}></AttributesTable>
                
              <Divider/>

              <DimensionsPanel dimensions={this.state.data.dimensions}></DimensionsPanel>
              <VariablesPanel></VariablesPanel>
              
              {this.containsGeoBoundaryOptions() &&
                <MapPanel></MapPanel>
              }

              {this.containsServerSideFunctions() &&
                <ExtraOptionsPanel></ExtraOptionsPanel>
              }
              
              <Divider/>

              <ExportContainer></ExportContainer>

              <Divider/>

              <DataUrl></DataUrl>

              <Drawer
                className="helpguide-drawer"
                title="Help for the DODS Dataset Access Form"
                placement="right"
                closable={false}
                onClose={this.toggleDrawer}
                visible={this.state.showDrawer}
                width="775">
                <HelpGuideView></HelpGuideView>
              </Drawer>
            </div>
          </Card>
        }

        {this.state.failedToLoad && 
          <ErrorPageView
            title="Oops!"
            subtitle="An error occured while loading the dataset form."
            message={ this.state.errorMessage }
            allowRefresh={false}
            redirectToCatalog={true}
          ></ErrorPageView>
        }
      </div>
    )
  }
  
}

const mapStateToProps = state => ({
  form: state.form
});

const mapDispatchToProps = (dispatch) => {
  return {
    initFormState(value){
      dispatch(initFormState(value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormView);

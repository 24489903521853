import React from 'react';
import './ExtraOptionsPanel.scss'
import ExtraOptionItem from './components/ExtraOptionItem/';
import { Collapse } from 'antd';
import { updateMainStateAttr  } from '../../actions';
import { connect } from "react-redux";

const { Panel } = Collapse;

class ExtraOptionsPanel extends React.Component {
  state = {
    serverSideFunctions: null
  }

  componentDidMount = () => {
    // We'll filter the array of server side functions for the geospatial_selection, since
    // that will be handled in the MapPanel.
    const serverSideFunctions = this.props.extraOptions.server_side_functions.filter(function (el) {
      return el.function_type !== 'geospatial_selection'
    });;

    this.setState({serverSideFunctions})
  }

  render() {
    return (
      <Collapse className="extra-options-panel form-collapse-panel">
        <Panel header="Extra Options">
          <div className="options-panel-container">
          
            {this.state.serverSideFunctions &&
              this.state.serverSideFunctions.map(function(item){
                return <ExtraOptionItem key={item.function_name} serverFunction={item}></ExtraOptionItem>
              })
            }

          </div>
        </Panel>
      </Collapse>
    )
  }
}

const mapStateToProps = state => ({
  extraOptions: state.form.extra_options
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraOptionsPanel);
import React from 'react';
import './CatalogItem.scss'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Select, Button, Tooltip, Collapse, DatePicker } from 'antd';
import { updateMainStateAttr } from '../../../../actions';


import { connect } from "react-redux";

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class CatalogItem extends React.Component {
  state = {
    version: '',
    dateDisplayFormat: 'YYYY-MM-DDTHH:mm:ss',
    dateFormat: 'YYYYMMDDTHH:mm:ss'
  }

  componentDidMount = () => {
    this.setState({ version: this.props.item.available_versions[0].version_id });
  }

  /**
   * Updates the state to the selected dataset version
   * 
   * @param {string} value - Dataset version
   */
  updateDatasetVersion = (value) => {
    this.setState({ version: value });
  }

  /**
   * builds the form URL to redirect to
   */
  buildFormURL = () => {
    const version = encodeURIComponent(this.state.version);
    const dataset = encodeURIComponent(this.props.item.dataset_id);
    return `/nwm/${dataset}/${version}`;
  }

  /**
   * action event for when the date range is set.
   * updates the redux state 
   * @param {string} type - start_time/end_time
   * @param {Moment} e 
   */
  setDateRange = (e) => {
    this.props.updateMainStateAttr({
      attr: 'start_time',
      value: moment(e[0]).format(this.state.dateFormat)
    })
    this.props.updateMainStateAttr({
      attr: 'end_time',
      value: moment(e[1]).format(this.state.dateFormat)
    })
  }

  /**
   * Disable any days after today
   * @param {*} current 
   */
  disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  render() {
    return (
      <div className="catalog-item">
        <div className="catalog-item-row">
          <div className="catalog-name">
            <span className="catalog-title">{this.props.item.dataset_title}</span>
            <span className="catalog-id">{this.props.item.dataset_id}</span>
          </div>

          <div className="select-container">
            <Tooltip title="Select dataset version.">
              <Select 
                className="version-select"
                defaultValue={this.props.item.available_versions[0].version_display_name} 
                onChange={this.updateDatasetVersion}
              >
                {this.props.item.available_versions.map(function(item){
                  return <Option key={item.version_id} value={item.version_id}>{item.version_display_name}</Option>;
                })}
              </Select>
            </Tooltip>
            
            <Link to={this.buildFormURL()}>
              <Button type="primary">View</Button>
            </Link>
            
          </div>
        </div>

        <Collapse className="datetime-select" bordered={false} ghost={true} activeKey={this.state.version}>
          <Panel showArrow={false} key={'best_valid_range'}>
            <div className="date-range-container">
              <RangePicker 
                className="date-range-picker"
                disabledDate={this.disabledDate}
                format={this.state.dateDisplayFormat}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                }}
                onChange={this.setDateRange}
                size={'small'}
              />
            </div>
          </Panel>
        </Collapse> 
      </div>
    )
  }
}

const mapStateToProps = state => ({
  exportUrl: state.form.locationURL,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogItem);
import React from 'react';
import './VariablesItem.scss'
import { connect } from "react-redux";
import { Checkbox, Input, Popover, Collapse} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { initVariableState, updateVariableDimensionState, updateVariableState } from '../../../../actions';
import VariableConstraint from '../VariableConstraint/';

const { Panel } = Collapse;

class VariablesItem extends React.Component {
  state = {
      enabled: false,
      operators: ['>=', '>', '<=', '<', '=', '=~', '!='],
      numConstraints: 2,
      loaded: false
  }

  componentDidMount = () => {
    this.props.initVariableState(this.props.variable);
  }

  componentDidUpdate(prevProps) {
    if (this.props.variable.dimensions.length && prevProps.dimensions) {
      for (let d in this.props.variable.dimensions) {
        const prevDimension = prevProps.dimensions.find(dim => dim.name === this.props.variable.dimensions[d].name);
        const currDimension = this.props.dimensions.find(dim => dim.name === this.props.variable.dimensions[d].name);

        if (currDimension.autofill !== prevDimension.autofill ) {
          this.props.updateVariableDimensionState({
            variable: this.props.variable,
            dimension: currDimension,
            min: currDimension.min,
            step: currDimension.step,
            max: currDimension.max,
          });
        }
      }
    }
  }


  /**
   * Returns html element for the variable item's popover
   */
  variablePopoverContent = () => (
    <div>
      <p className="content-popover">
        {Object.keys(this.props.variable.attrs).map((key) => {
          return <span className="attr-row" key={key}><b>{key}</b>: {this.props.variable.attrs[key]}<br></br></span>;
        })}
        </p>
    </div>
  );

  /**
   * Given a variable item's state, return a formatted string
   * format - {min}:{step}:{max}
   * 
   * @param {*} variable - variable item state
   */
  buildVariableInputString = variable => {
    const variableItem = this.props.variables.find(v => v.name === this.props.variable.name) ? this.props.variables.find(v => v.name === this.props.variable.name) : '';
    const varDimension = variableItem.dimensions.find(d => d.name === variable.name) ? variableItem.dimensions.find(d => d.name === variable.name) : '';
    return `${varDimension.min}:${varDimension.step}:${varDimension.max}`;
  }

  /**
   * On click event for the variable checkbox
   * Updates/toggles the variable's selected state
   * @param {*} e - event
   */
  onCheckboxChange = e => {
    this.setState({
      enabled: e.target.checked,
    },()=>{
      this.props.updateVariableState({
        variable: this.props.variable,
        type: 'enabled',
        value: this.state.enabled
      });
    });
  };

  /**
   * Parses and updates the min/step/max for the variable's dimension
   * 
   * 
   * @param {*} e - event (event.target.value is in the format [min:step:max])
   * @param {*} dimension - variable's dimension being updated
   */
  onVariableDimensionInputChange = (e, dimension) => {
    const inputValue = e.target.value;
    let values = inputValue.split(":");
    this.props.updateVariableDimensionState({
      variable: this.props.variable,
      dimension: dimension,
      min: values[0] ? values[0] : '',
      step: values[1] ? values[1] : '',
      max: values[2] ? values[2] : '',
    })
  }


  /**
   * Making sure that our redux state exists for our current variable.
   * Only render the variable item if exists, since we rely on it to populate and manipulate the form.
   */
  checkIfReady = () => {
    const variable = this.props.variables.find(v => v.name === this.props.variable.name);
    return variable.ready;
  }

  /**
   * Returns the html element for the constraints row.
   * We define the numConstraints to be rendered in this.state
   */
  renderConstraints = () => {
    let constraints = [];
    for(let i = 0; i < this.state.numConstraints; i++) {
      constraints.push(<VariableConstraint 
        key={i} 
        disabled={!this.props.variable.enabled}
        variable={this.props.variable}
        position={i}
        ></VariableConstraint>)
    }
    return constraints;
  }

  /**
   * Dimension sizes that can range from 1 digit to 7+ digits.
   * Because of this, we want to set the width of the input fields dynamically, instead of using a set width.
   * Here we will determine the width by numDigits x pixelsPerDigit
   * 
   * @param {object} dimension 
   */
  getInputWidth = (dimension) => {
    let pixelsPerDigit = 37;
    let numDigits = JSON.stringify(dimension.size).length;
    return numDigits*pixelsPerDigit;
  }

  render() {
    return (
      <div className="variable-item">
      {this.checkIfReady() &&
        <div className="v-row-container">
          <div className="v-row">
            <div className="column">
              <Checkbox className="variable-check" checked={this.props.variable.enabled} onChange={this.onCheckboxChange}>
                <span className="variable-label">{this.props.variable ? this.props.variable['name'] : ''}</span>
                <Popover content={this.variablePopoverContent}><QuestionCircleOutlined /></Popover>
              </Checkbox>
            </div>

            {this.props.variable.dimensions.length > 0 &&
            <div className="column">
              <div className="variables-dimensions-input">
                {Object.keys(this.props.variable.dimensions).map((key) => {
                  return <Input name="d-value" 
                    key={this.props.variable.dimensions[key].name} 
                    className="variable-dimension-input" 
                    addonBefore={this.props.variable.dimensions[key].name} 
                    disabled={!this.props.variable.enabled}
                    value={this.buildVariableInputString(this.props.variable.dimensions[key])}
                    onChange={(e) => this.onVariableDimensionInputChange(e, this.props.variable.dimensions[key])}
                    style={{ width: this.getInputWidth(this.props.variable.dimensions[key]) }}>
                  </Input>
                })}
              </div>
            </div>
            }
          </div>

          {this.props.variable.dimensions.length > 0 &&
          <Collapse className="variable-options" bordered={false} >
            <Panel className="var-expand" header="View options" showArrow={false} key="1">
              <div className="variable-constraints">
                {this.renderConstraints()}                
              </div>
            </Panel>
          </Collapse>
          }

        </div>
      }
    </div>
    )
  }
}


const mapStateToProps = state => ({
  dimensions: state.form.dimensions,
  variables: state.form.variables
});

const mapDispatchToProps = (dispatch) => {
  return {
    initVariableState(value){
      dispatch(initVariableState(value));
    },
    updateVariableDimensionState(value){
      dispatch(updateVariableDimensionState(value));
    },
    updateVariableState(value){
      dispatch(updateVariableState(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesItem);
import React from 'react';
import './DataUrl.scss';
import { Typography } from 'antd';

import { connect } from "react-redux";
import { updateMainStateAttr  } from '../../actions';


const { Text } = Typography; 
class DataUrl extends React.Component {
  state = {
    returnURL: ''
  }

  componentDidMount = () => {
    this.setState({returnURL: this.props.path});
  }

  /**
   * Function builds and return a URL string with the dimensions, variables and constraints
   */
  buildURL = () => {
    let rootURL = `${process.env.REACT_APP_BACKEND_SERVICE_URL}/dap/${this.props.path}`;
    let dimensions = [];
    let constraints = [];

    // loop through selected variables
    for (let v in this.props.variables) {
      let variable = this.props.variables[v];
      if (variable.enabled) {
        let query = `${variable.name}`;

        // loop through the dimensions in current variable
        for (let d in variable.dimensions) {
          let dimension = variable.dimensions[d];
          let dRange = `[${dimension.min}:${dimension.step}:${dimension.max}]`;
          query = query.concat('', dRange);
        }
        dimensions.push(query);

        // loop through the constraints in current variable
        for (let c in variable.constraints) {
          let constraint = variable.constraints[c];
          if (constraint.value) {
            let constraintString = `&${variable.name}${constraint.operator}${constraint.value}`;
            constraints.push(constraintString);
          }
        }
      }
    }

    // Add the geoShapeQuery if geoShapeString is defined in our redux state.
    if (this.props.geoShapeString) {
      dimensions.push(this.props.geoShapeString)
    }

    // Add the extraOptions functions, if available.
    const extraOptions = this.getExtraOptions();
    if (extraOptions) {
      dimensions.push(extraOptions);
    }
    
    const dimensionQuery = dimensions.length > 0 ? `?${dimensions}` : '';
    const variableConstraints = constraints.length > 0 ? `${constraints.join("")}` : '';
    const exportFormat = this.props.exportFormat === 'ascii' ? '.txt' : this.props.exportFormat;
    
    return rootURL + encodeURI(exportFormat + dimensionQuery + variableConstraints);
  }

  /**
   * This will check what extra options functions are enabled, and will be included in the url build
   */
  getExtraOptions = () => {
    let extraOptions = [];

    const serverSideFunctions = this.props.extraOptions.server_side_functions;
    // this filtering is a bit messy since it's possible to set the user_value to 0
    const validFunctions = serverSideFunctions.filter(func => func.hasOwnProperty('user_value') && func.user_value !== '' && func.user_value !== null )

    for (let i in validFunctions) {
      extraOptions.push(`${validFunctions[i].function_name}(${validFunctions[i].user_value})`)
    }
    
    // return null if there's no extra options selected
    return extraOptions.length > 0 ? extraOptions : null;
  }

  componentDidUpdate() {
    let url = this.buildURL();
    if (url !== this.state.returnURL) {
      this.setState({
        returnURL: this.buildURL()
      });

      this.props.updateMainStateAttr({
        attr: 'exportURL',
        value: url
      })
    }
  }

  render() {
    return (
      <div className="url-container">
        <b className="row-title">Data URL:</b>
        <Text className="row-content data-url" copyable>{ this.state.returnURL }</Text>
        <br></br>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  variables: state.form.variables,
  dimensions: state.form.dimensions,
  path: state.form.path,
  exportFormat: state.form.exportFormat,
  locationURL: state.form.locationURL,
  geoShapeString: state.form.geoShapeString,
  extraOptions: state.form.extra_options
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataUrl);



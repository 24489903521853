export const initFormState = (response) => {
  return {
    type: 'INIT_FORM_STATE',
    payload: response
  }
}

export const initCatalogState = (response) => {
  return {
    type: 'INIT_CATALOG_STATE',
    payload: response
  }
}

export const updateDimensionState = (value) => {
  return {
    type: 'UPDATE_DIMENSION_STATE',
    payload: value
  }
}

export const initVariableState = (value) => {
  return {
    type: 'INIT_VARIABLE_STATE',
    payload: value
  }
}

export const updateVariableState = (value) => {
  return {
    type: 'UPDATE_VARIABLE_STATE',
    payload: value
  }
}

export const updateVariableDimensionState = (value) => {
  return {
    type: 'UPDATE_VARIABLE_DIMENSION_STATE',
    payload: value
  }
}

export const updateVariableConstraintState = (value) => {
  return {
    type: 'UPDATE_VARIABLE_CONSTRAINT_STATE',
    payload: value
  }
}

export const toggleDimensionAutofill = (value) => {
  return {
    type: 'TOGGLE_DIMENSION_AUTOFILL',
    payload: value
  }
}

export const updateExportFormat = (value) => {
  return {
    type: 'UPDATE_EXPORT_FORMAT_STATE',
    payload: value
  }
}

export const updateMainStateAttr = (value) => {
  return {
    type: 'UPDATE_MAIN_STATE_ATTR',
    payload: value
  }
}

export const updateExtraOptionStateAttr = (value) => {
  return {
    type: 'UPDATE_EXTRA_OPTION_STATE_ATTR',
    payload: value
  }
}
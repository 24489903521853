import React from 'react';
import './HelpGuideView.scss';
import { Button } from 'antd';
import { QuestionCircleOutlined, FormOutlined } from '@ant-design/icons';

export default class HelpGuideView extends React.Component {
  render() {
    return (
      <div className="helpguide-body">
        <p>The Dataset Access Form allows users to request specific data by selecting Dimensions, Variables and specifying their constraints.</p>

        <h3>The Dimensions panel</h3>
        <p>The dimensions panel is used as a tool to quickly set the Variable dimensions in the Variables panel. To learn more about a certain dimension, hover over the<QuestionCircleOutlined /> icon. </p>
        <p>For each available dimension, the min, max, and step values can be set. 
          <img className="helpguide-img sm" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/dim01.png`} 
            alt="dim01"
          />
        </p>
        <p>The min/max values can be set by manually entering in the input field, or by adjusting the slider. Once finished, select the Apply button<Button className="autofill-button" size="small" type="primary" shape="circle" icon={<FormOutlined />}/> to set the configured min/max/step to all the matching dimensions value found in the <b>Variables panel</b>.</p>

        <br></br>
        <h3>The Variables panel</h3>
        <p>By default, all the variables are unselected on the form. If this is unchanged, it will assume that the full range of data for all dimensions, 
          for all variables, is being requested. To learn more about a certain variable, hover over the<QuestionCircleOutlined /> icon.</p>

        <p>The variable inputs are disabled unless a variable is checked.
          <img className="helpguide-img sm" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/var01.png`} 
            alt="var01"
          />
        </p>
        

        <p>If the feature_id dimension configuration from the Dimensions panel is applied, the change will be reflected here.
          
          <img className="helpguide-img sm" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/var02.png`} 
            alt="var02"
          /></p>
          
        <p>There is also a “View options” to expand and set additional constraints.
          <img className="helpguide-img sm" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/var03.png`} 
            alt="var03"
          />
        </p>

        <br></br>
        <h3>Geospatial Constraints</h3>
        <p>A bounding box or polygon can be used to select data points of interest. The geospatial constraints can be set using the interactive map component, or manually using the function syntax in the data URL. </p>
        <img className="helpguide-img" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/geo01.png`} 
            alt="geo1"
          />
        <br></br>
        <p>The <b>bounding box</b> function uses the syntax <span className="code-format">bbox(min_lon,min_lat,max_lon,max_lat)</span> where lon/lat stand for longitude and latitude, respectively. For example, to select a subset for streamflow with a few time steps and a bounding box, one can submit the following request:</p>
        <p className="code-format">http://prototype.ioos.us/dap/noaa.nwm.short_range.channel_rt/run/latest.dods?time[0:1:3],streamflow[0:1:3],bbox(-74.568329,40.393627,-71.954956,41.315981)</p>
        <br></br>
        <p>The <b>polygon</b> function uses the syntax <span className="code-format">polygon('POLYGON((lng1 lat1,lng2 lat2, ... ,lng1 lat1))')</span> where lon/lat stand for longitude and latitude, respectively. For example, to select a subset for streamflow with a few time steps and a polygon, one can submit the following request:</p>
        <p className="code-format">http://prototype.ioos.us/dap/noaa.nwm.short_range.channel_rt/run/latest.dods?polygon('POLYGON((-112.9729 48.7774,-119.1148 37.5517,-89.7178 35.9219,-112.9729 48.7774))')</p>
        <br></br>
        <h3>Extra Options</h3>
        <p>Additional server side functions can be accessed by expanding the "Extra Options" panel. To learn more about a certain function, hover over the<QuestionCircleOutlined /> icon. </p>
        <p>The Data Access API facilitates easy filtering of terminal/non-terminal stream points. The NHDPlus database is used to match COMIDs (the "feature_id" in the NWM datasets) for those stream points which are terminal or non-terminal, based on a true/false flag. Below are two examples of using the NHDPlus database to filter out terminal points for the Delaware Bay and Chesapeake Bay.</p>
        <img className="helpguide-img terminal" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/terminal_pts1.png`} 
            alt="delaware"
        />
        <img className="helpguide-img terminal" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/terminal_pts2.png`} 
            alt="chesapeakebay"
        />
        <br></br>
        <br></br>
        <h3>Data Export</h3>
        <p>After the variables are set, the specified dataset can be downloaded at the bottom of the form. Select the desired file format and press the Export button. <i>(large .dods request may take some time to generate)</i></p>

        <p>A URL is also generated for the request. Select the Copy button to add it to your clipboard.
          <img className="helpguide-img" 
            src={`${process.env.PUBLIC_URL}/assets/images/helpguide/data01.png`} 
            alt="data01"
          />
        </p>

      </div>
    )
  }
}
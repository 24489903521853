
const formReducer = (state = {}, action) => {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch(action.type) {
    case 'INIT_CATALOG_STATE' :
      return Object.assign({},stateCopy, action.payload);

    default :
      break;
  }
  return state;
};

export default formReducer;
import React from 'react';
import './VariableConstraint.scss'
import { Input, Select } from 'antd';

import { connect } from "react-redux";
import { updateVariableConstraintState } from '../../../../actions';

const { Option } = Select;

class VariableConstraint extends React.Component {
  state = {
    operators: ['>=', '>', '<=', '<', '=', '=~', '!='],
    selectedOperator: null,
    value: ''
  }

  /**
   * On change event when updating the selected operator from the dropdown.
   * Sets state, updates redux state
   * @param {event} e - event
   */
  updateSelectedOperator = (e) => {
    this.setState({
      selectedOperator: e
    })
    
    this.props.updateVariableConstraintState({
      variable: this.props.variable,
      position: this.props.position,
      operator: e,
      value:    this.state.value
    })
  }


  /**
   * On change event for the constraint text input.
   * Filters the input then updates the state.
   * @param {event} e 
   */
  updateValue = (e) => {
    const value = e.target.value
    .replace(/[^0-9.-]/g, '')       // remove chars except number, hyphen, point. 
    .replace(/(\..*)\./g, '$1')     // remove multiple points.
    .replace(/(?!^)-/g, '')         // remove middle hyphen.
    .replace(/^0+(\d)/gm, '$1');    // remove multiple leading zeros. <-- I added this.
    this.setState({
      value: value
    })

    this.props.updateVariableConstraintState({
      variable: this.props.variable,
      position: this.props.position,
      operator: this.state.selectedOperator,
      value:    value
    })
  }

  render() {
    return (
      <div>
        <Select className="grid" 
        value={this.state.selectedOperator} 
        onChange={(e) => this.updateSelectedOperator(e)}
        style={{ width: 60}} 
        disabled={this.props.disabled}
        >
          {this.state.operators.map(function(operator){
              return <Option key={operator} value={operator}>{operator}</Option>; 
          })}
        </Select>
        <Input className="variable-input"
          disabled={this.state.selectedOperator === null}
          value={this.state.value}
          onChange={(e) => this.updateValue(e)}          
          ></Input>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  variables: state.form.variables,
  dimensions: state.form.dimensions
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateVariableConstraintState(value){
      dispatch(updateVariableConstraintState(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableConstraint);
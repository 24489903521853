import React from 'react';
import './DimensionsItem.scss';
import { Input, Slider, Popover, Button, Tooltip } from 'antd';
import { QuestionCircleOutlined, FormOutlined} from '@ant-design/icons';

import { connect } from "react-redux";

import { updateDimensionState } from '../../../../actions'


class DimensionsItem extends React.Component {
  state = {
    isLoaded: false,
    enabled: true,
    dimension: null,
    variable: null,
    checked: false,
    minValue: this.props.dimension.min ? this.props.dimension.min : 0,
    maxValue: 0,
    stepValue: 1,
    applyBtnTooltipText: `Apply value to all "${this.props.dimension.name}" fields`
  }

  componentDidMount = () => {
    this.initDimensionState();
    const variable = this.props.variables.find(variable => variable.name === this.props.dimension.name)
    this.setState( { 
      dimension: this.props.dimension,
      variable: variable,
      maxValue: this.props.dimension.size-1,
      isLoaded: true    
    });
  }

  /**
   * toggle enable status with the checkbox
   * if state is enabled, populate the dimension fields
   * 
   */
  onCheckboxChange = event => {
    this.setState({ enabled: event.target.checked },()=>{
      this.props.updateDimensionState({
        dimension: this.state.dimension,
        type: 'enabled',
        value: this.state.enabled
      })
    });
  };

  /**
   * Initializing the dimensions' state with default attributes
   */
  initDimensionState = () => {
    const defaultDimension = [{
      type: 'min',
      value: 0
    },
    {
      type: 'max',
      value: this.props.dimension.size-1
    },
    {
      type: 'step',
      value: 1
    },
    {
      type: 'enabled',
      value: false
    },
    {
      type: 'autofill',
      value: false
    }]

    for (let d in defaultDimension) {
      this.props.updateDimensionState({
        dimension: this.props.dimension,
        type: defaultDimension[d].type,
        value: defaultDimension[d].value
      })
    }
  };


  /**
   * Can take a number/string as value, 
   * returns the value as a comma seperated number.
   * @param {Number/String} value 
   */
  formatNum = value => {
    return value.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," );
  }

  /**
   * On change event when adjusting the slider.
   * Updates the appropriate states
   * @param {event} value 
   */
  onSliderChange = value => {
    this.setState({
      minValue: value[0],
      maxValue: value[1],
    });

    this.props.updateDimensionState({
      dimension: this.state.dimension,
      type: 'min',
      value: value[0]
    })

    this.props.updateDimensionState({
      dimension: this.state.dimension,
      type: 'max',
      value: value[1]
    })
  }

  /**
   * On change event for dimension input
   * Filters the input.
   * Sets the appropriate states.
   * @param {event} e 
   */
  onInput = e => {
    const value = e.target.value.replace(/\D/g,'');
    
    if (e.target.name === 'min') {
      // TODO: Some validations and stuff
      this.setState( {
        minValue: value
      })
    }

    if (e.target.name === 'max') {
      // TODO: Some validations and stuff
      this.setState( {
        maxValue: value
      })
    }

    if (e.target.name === 'step') {
      // TODO: Some validations and stuff
      this.setState( {
        stepValue: value
      })
    } 

    this.props.updateDimensionState({
      dimension: this.state.dimension,
      type: e.target.name,
      value: Number(value)
    })
  }

  /**
   * Returns the html element for the dimension popover
   */
  dimensionPopoverContent = () => (
    <div>
      <p className="content-popover">
      {Object.keys(this.state.variable.attrs).map((key) => {
            return <span className="attr-row" key={key}><b>{key}</b>: {this.state.variable.attrs[key]}<br></br></span>;
          })}
      </p>
    </div>
  );

  /**
   * Toggles the dimension autofill state.
   * Updating this state attribute will trigger all dimensions to update accordingly.
   */
  toggleDimensionAutofill = () => {
    const dimension = this.props.dimensions.find(d => d.name === this.state.dimension.name)
    this.props.updateDimensionState({
      dimension: this.state.dimension,
      type: 'autofill',
      value: !dimension.autofill
    })
    this.updateApplyBtnTooltipText()
  }

  /**
   * Update the button tooltip to show that the values have been applied.
   * Revert back to previous tooltip message after 2 seconds
   */
  updateApplyBtnTooltipText = () => {
    let self = this
    this.setState( {
      applyBtnTooltipText: "Value applied!"
    })

    setTimeout(function(){ 
      self.setState( {
        applyBtnTooltipText: `Apply value to all "${self.props.dimension.name}" fields`
      })
    }, 2000);
  }

  render() {
    return (
      <div className="dimensions-item">
        {this.state.isLoaded && 
        <div className="dimension-row">
          <div className="dimension-title">
            <span className="dimension-label">{this.state.dimension ? this.state.dimension['name'] : ''}</span>
            <Popover className="dimensions-popover" content={this.dimensionPopoverContent}>
              <QuestionCircleOutlined />
            </Popover>
          </div>
          <div className="dimension-input-container">
          <Input name="min" className="dimension-input" addonBefore="min" disabled={!this.state.enabled} onChange={this.onInput} value={this.formatNum(this.state.minValue)}></Input>
          <Input name="max" className="dimension-input" addonBefore="max" disabled={!this.state.enabled} onChange={this.onInput} value={this.formatNum(this.state.maxValue)}></Input>
          <Input name="step" className="dimension-input step" addonBefore="step" disabled={!this.state.enabled} onChange={this.onInput} value={this.state.stepValue}></Input>
          <Tooltip title={this.state.applyBtnTooltipText}>
            <Button className="autofill-button" size="small" type="primary" shape="circle" icon={<FormOutlined />} disabled={!this.state.enabled} onClick={this.toggleDimensionAutofill}/>
          </Tooltip>
          </div>

          <Slider range value={[this.state.minValue, this.state.maxValue]} min={0} max={this.state.dimension.size-1} disabled={!this.state.enabled} onChange={this.onSliderChange}/>
        </div>
        }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  variables: state.form.variables,
  dimensions: state.form.dimensions 
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateDimensionState(value){
      dispatch(updateDimensionState(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsItem);
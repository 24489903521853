import React from 'react';
import './AttributesTable.scss';
import { Table, Collapse } from 'antd';

const { Panel } = Collapse;
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  }
]

export default class AttributesTable extends React.Component {
  state = {
    tableData: [],
    expand: false
  }

  componentDidMount() {
    const attributes = this.props.attributes;
    let tableData = []
    for (let key in attributes) {
      let attribute = {
        key: key,
        name: key,
        value: attributes[key]
      }
      tableData.push(attribute);
    }
    this.setState({ tableData })
  }

  /**
   * On click event for Display/Hide Global Attributes 
   * Toggles the expand state.
   */
  toggleGlobalAttributes = () => {
    this.setState({ expand: !this.state.expand});
  }

  render() {
    return (
      <div className="attribute-table">
        <p className="expand-label" onClick={(e) => this.toggleGlobalAttributes()}>{this.state.expand ? 'Hide Global Attributes' : 'Display Global Attributes'}</p>
        <Collapse bordered={false} activeKey={[ this.state.expand ? 'attr' : null]}>
          <Panel key="attr" > 
            <Table columns={columns} dataSource={this.state.tableData} size="small" pagination={{ pageSize: 50 }}/>
          </Panel>
        </Collapse>
      </div>
    )
  }
}
const defaultState = {
  exportFormat: '.dods',
  exportURL: '',
  geoShapeString: '',
}

const formReducer = (state = defaultState, action) => {
  let stateCopy = JSON.parse(JSON.stringify(state));
  let variable;
  let dimension;
  switch(action.type) {
    case 'INIT_FORM_STATE' :
      // copy extra options and export formats
      stateCopy['extra_options'] = action.payload.extra_options;
      stateCopy['export_formats'] = action.payload.export_formats;
      return Object.assign({},stateCopy, action.payload.dataset);


    case 'UPDATE_DIMENSION_STATE' :
      dimension = stateCopy.dimensions.find(dim => dim.name === action.payload.dimension.name)
      dimension[action.payload.type] = action.payload.value
      return Object.assign({},stateCopy);


    case 'INIT_VARIABLE_STATE' : 
      variable = stateCopy.variables.find(v => v.name === action.payload.name)
      variable.enabled = false;
      variable.ready = true;
      variable.constraints = [];

      for (let d in variable.dimensions) {
        dimension = variable.dimensions[d];
        dimension.min = 0;
        dimension.max = dimension.size;
        dimension.step = 1;
      }
      return Object.assign({},stateCopy);
    

    case 'UPDATE_VARIABLE_DIMENSION_STATE' : 
      variable = stateCopy.variables.find(variable => variable.name === action.payload.variable.name)
      dimension = variable.dimensions.find(d => d.name === action.payload.dimension.name)

      dimension.min = action.payload.min
      dimension.max = action.payload.max
      dimension.step = action.payload.step
      return Object.assign({},stateCopy);


    case 'UPDATE_VARIABLE_STATE' :
      variable = stateCopy.variables.find(variable => variable.name === action.payload.variable.name)
      variable[action.payload.type] = action.payload.value
      return Object.assign({},stateCopy);


    case 'UPDATE_VARIABLE_CONSTRAINT_STATE' :
      variable = stateCopy.variables.find(variable => variable.name === action.payload.variable.name)
      variable.constraints[action.payload.position] = {
        operator: action.payload.operator,
        value: action.payload.value
      } 
      return Object.assign({},stateCopy);

    case 'UPDATE_MAIN_STATE_ATTR' :
      stateCopy[action.payload.attr] = action.payload.value;
      return Object.assign({},stateCopy);

    case 'UPDATE_EXTRA_OPTION_STATE_ATTR' :
      // grabbing only the serverside functions 
      let serversideFunctions = stateCopy.extra_options.server_side_functions;

      // getting our function that we will update
      let functionToUpdate = serversideFunctions.find(func => func.function_name === action.payload.type)

      // creating a new 'user_value' attribute, and setting it to our payload value;
      functionToUpdate['user_value'] = action.payload.value
      return Object.assign({},stateCopy);
      
    default :
      break;
  }
  return state;
};

export default formReducer;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';

import CatalogView from './components/CatalogView/';
import FormView from './components/FormView/';
import ErrorPageView from './components/ErrorPageView/';

class App extends React.Component {
  /**
   * Takes a param argument to look up in the current URL.
   * Returns the matching value, or null if not found.
   * 
   * @param {string} param - The param to look up in the URL
   */
  getQueryParam = (param) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === param) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
  }

  componentDidMount() {
    let requestURL;
    let requestLocation = process.env.REACT_APP_BACKEND_SERVICE_URL

    // Log some info out in the console for debugging use. 
    if (this.getQueryParam('debug')) {
      console.log('Window Location: ' + JSON.stringify(window.location, null, 2))
      console.log('Request location: ' + JSON.stringify(requestLocation))
      console.log('Request URL: ' + JSON.stringify(requestURL))
    }
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path='/nwm/:dataset/:version'  component={FormView} />     
          <Route path={['/nwm']} component={CatalogView} />   
          <Route component={() => <ErrorPageView 
            title="404"
            subtitle="Error: Page not found."
            redirectToCatalog={true}
            icon="warning"></ErrorPageView>} />      
        </Switch>
      </div>
    )
  }
}


export default App;
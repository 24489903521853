import React from 'react';
import './ErrorPageView.scss';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { FrownOutlined, BookOutlined, WarningOutlined } from '@ant-design/icons';

export default class ErrorPageView extends React.Component {
  state = {
    title: this.props.title ? this.props.title : 'An error has occured.',
    subtitle: this.props.subtitle ? this.props.subtitle : '',
    message: this.props.message ? this.props.message : '',
    allowRefresh: this.props.allowRefresh ? this.props.allowRefresh : false,
    redirectToCatalog: this.props.redirectToCatalog ? this.props.redirectToCatalog : false,
    color: this.props.color ? this.props.color : '#1890ff',
    icon: this.props.icon ? this.props.icon : 'sadface'
  }

  /**
   * Refresh the page
   */
  refreshPage = () => {
    window.location.reload();
  }

  /**
   * Returns the selected icon to display
   */
  getIcon = () => {
    let icons = {
      sadface: <FrownOutlined style={{ color: this.state.color }}/>,
      warning: <WarningOutlined style={{ color: this.state.color }}/>
    }
    return icons[this.state.icon]
  }

  render() {
    return (
      <div className="error-page-view">
        <Card>
          <div className="error-icon">
            { this.getIcon() }
          </div>
          <h1 className="error-title">{this.state.title}</h1>
          <h3 className="error-subtitle">{this.state.subtitle}</h3>

          {this.state.message &&
            <p className="error-message"><b>{this.state.message.code}: </b>{this.state.message.message}</p>
          } 
          {this.state.allowRefresh &&
            <p className="error-refresh-message">
              <span className="error-link" style={{ color: this.state.color }}onClick={() => this.refreshPage()}>Refresh the page</span>, or try again later.
            </p>  
          }
        </Card>
          {this.state.redirectToCatalog &&
            <p className="error-return-message">
              <Link to={`/nwm`} className="error-link" style={{ color: this.state.color }}>
                <BookOutlined className="error-return-icon" />Go to Catalog.
              </Link>
            </p>  
          }
      </div>
    )
  }
}
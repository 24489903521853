import React from 'react';
import './ExportContainer.scss'
import { Button, Select, Alert } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { connect } from "react-redux";
import { updateMainStateAttr  } from '../../actions';

const { Option } = Select;

class ExportContainer extends React.Component {
  state = {
    fetching: false,
    displayAlert: false,
    alertType: null,
    alertMsg: null
  }

  /**
   * On change event when updating the export format in the dropdown.
   * Updates the state.
   * @param {event} value 
   */
  updateExportFormat = (value) => {
    this.props.updateMainStateAttr({
      attr: 'exportFormat',
      value: value
    });
  }

  /**
   * Builds the filename for the export download
   */
  getFilename = () => {
    let version = this.props.path.split('/')[this.props.path.split('/').length - 1]
    return `${version}${this.props.selectedExportFormat}`
  }


  /**
   * Fetches the download item,
   * Sets up the filename, trigger download.
   * @param {string} url 
   */
  downloadItem = (url) => {
    let self = this;
    this.fetchExportItem(url).then(function (xhr) {
      const type = xhr.getResponseHeader('content-type');
      const blob = new Blob([xhr.response], { type: type });
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = self.getFilename();
      document.body.appendChild(a);
      a.click();
      self.setState({ 
        fetching: false
      });
    }).catch(function(xhr) {
      let res
      try {
        res = JSON.parse(xhr.responseText);
      } catch (e) {
        res = null;
      }

      let msg = <span>{ res && res.code ? `${res.code}: ${res.message}` : 'An error has occured while requesting this export.'}</span>

      self.setState({ 
        fetching: false,
        displayAlert: true,
        alertType: 'error',
        alertMsg: msg
      });
    });
  }

  /**
   * Function fetches the export item and returns the response.
   * 
   * @param {string} url - the request url we'll be fetching from
   */
  fetchExportItem = (url) => {
    return new Promise(function (resolve, reject) { 
      let xhr = new XMLHttpRequest();
      // We initially set the responseType as text because if there's an error while fetching, the server returns the response in text format.
      // We will set the responseType to arrayBuffer later during the call.
      
      // IE doesn't like this line, commenting out for now.
      //xhr.responseType = 'text';
      
      xhr.open('GET', url);
      xhr.send();

      xhr.onreadystatechange = function() {
        // We can change the responseType mid-call if we haven't reached readyState = 3
        // If we notice that the response status is 200, we can update responseType from text to arraybuffer
        if (xhr.readyState < 3 && xhr.status === 200) {
          xhr.responseType = 'arraybuffer';
        } 
      }

      xhr.onload = function() {
        if (xhr.status === 200) { 
          resolve(xhr);
        } else { 
          reject(xhr);
        }
      }

      xhr.onerror = function() {
        reject(xhr);
      };
    });
  }

  /**
   * Returns the icon to use for the Export button
   */
  getExportBtnIcon = () => {
    return this.state.fetching ? <LoadingOutlined /> : <DownloadOutlined />
  }

  /**
   * On click event for when the export button is clicked.
   */
  exportBtnClicked = () => {
    // For .das and .dds formats, we'll open up a new window to display the data instead of downloading
    if (this.props.selectedExportFormat === '.das' || this.props.selectedExportFormat === '.dds' ) {
      window.open(this.props.exportUrl, "_blank");
      return;
    }

    this.setState({ 
      fetching: true,
      displayAlert: false
    });

    this.downloadItem(this.props.exportUrl)  
  }

  /**
   * Updates the state to hide the Alert
   */
  closeAlert = () => {
    this.setState({ 
      displayAlert: false
    });
  }

  render() {
    return (
      <div className="export-container">
        {this.state.displayAlert &&
          <Alert className="export-alert" message={this.state.alertMsg} type={this.state.alertType} onClose={this.closeAlert} showIcon closable />
        }
        <div className="export-selection-container">
          <Select onChange={this.updateExportFormat} defaultValue=".dods" style={{ width: 120 }} >
            {
                /* Render each option in the drop-down */
                this.props.exportFormats.map(
                    exportFormat => {
                        return ( <Option key={exportFormat} value={`.${exportFormat}`}> {`.${exportFormat}`} </Option> );
                    }
                )
            }
          </Select>
          <Button onClick={this.exportBtnClicked} className="export-button" type="primary" icon={this.getExportBtnIcon()} loading={this.state.fetching}>Export</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  variables: state.form.variables,
  dimensions: state.form.dimensions,
  exportUrl: state.form.exportURL,
  exportFormats: state.form.export_formats,
  selectedExportFormat: state.form.exportFormat,
  path: state.form.path
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateMainStateAttr(value){
      dispatch(updateMainStateAttr(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportContainer);

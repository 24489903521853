import React from 'react';
import './DimensionsPanel.scss'
import DimensionsItem from './components/DimensionsItem/';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export default class DimensionsPanel extends React.Component {
  render() {
    return (
      <Collapse className="dimension form-collapse-panel"  defaultActiveKey={['1']}>
        <Panel header="Dimensions" key="1">
          <div className="dimensions-container">
            {this.props.dimensions.map(function(item){
              return <DimensionsItem key={item['name']} dimension={item}></DimensionsItem>;
            })}
          </div>
        </Panel>
      </Collapse>
    )
  }
}
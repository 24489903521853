import React from 'react';
import './VariablesPanel.scss'
import { connect } from "react-redux";

import VariablesItem from './components/VariablesItem/';
import { updateVariableState } from '../../actions';
import { Collapse, Button } from 'antd';

const { Panel } = Collapse;

class VariablesPanel extends React.Component {
  state = {
    enabled: false,
    selectAll: false
  }

  /**
   * On click event for the "Select All" button.
   * Toggles the redux selected state for all the variables
   */
  selectAll = () => {
    this.setState({selectAll: !this.state.selectAll});
    for (let v in this.props.variables) {
      this.props.updateVariableState({
        variable: this.props.variables[v],
        type: 'enabled',
        value: !this.state.selectAll
      })
    }
  }

  /**
   * Returns a button label, value dependent on the selectAll state.
   */
  selectAllButtonLabel = () => {
    return this.state.selectAll ? 'Deselect All' : 'Select All'
  }

  render() {
    const self = this;
    return (
        <Collapse className="variable form-collapse-panel" defaultActiveKey={['2']}>
          <Panel header="Variables" key="2">
            <div className="variables-container">
              {/* 
              These are some very cheap actions to perform.
              We will iterate through dataset variables and only render the variables with dimensions first.
              And then another run through, and only render the variables without dimensions.
              This way all the variables that don't have dimensions are rendered together at the bottom the of list. */}
              {this.props.variables.map(function(item){
                if (item.dimensions.length > 0) {
                  return <VariablesItem key={item['name']} variable={item} dimensions={self.props.dimensions}></VariablesItem>; 
                }
                return null;
              })}
              {this.props.variables.map(function(item){
                if (item.dimensions.length === 0) {
                  return <VariablesItem key={item['name']} variable={item} dimensions={self.props.dimensions}></VariablesItem>; 
                }
                return null;
              })}
              <Button className="select-all" type="primary" size="small" onClick={this.selectAll}>
                {this.selectAllButtonLabel()}
              </Button>
            </div>
        </Panel>
      </Collapse>
    )
  }
}

const mapStateToProps = state => ({
  dimensions: state.form.dimensions,
  variables: state.form.variables
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateVariableState(value){
      dispatch(updateVariableState(value));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesPanel);